import PropTypes from "prop-types";
import { useState, createContext } from "react";
import { singleProjectData as singleProjectDataJson } from "../data/singleProjectData";

const SingleProjectContext = createContext();

export const SingleProjectProvider = ({ children }) => {
  const [singleProjectData, setSingleProjectData] = useState(
    singleProjectDataJson
  );

  return (
    <SingleProjectContext.Provider
      value={{ singleProjectData, setSingleProjectData }}
    >
      {children}
    </SingleProjectContext.Provider>
  );
};

SingleProjectProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

SingleProjectProvider.defaultProps = {};

export default SingleProjectContext;
