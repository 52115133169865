import AboutMeBio from "../components/about/AboutMeBio";
import { AboutMeProvider } from "../context/AboutMeContext";
import { motion } from "framer-motion";
import BasicLayout from "../components/layout/BasicLayout";

const About = () => {
  return (
    <AboutMeProvider>
      <BasicLayout >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, delay: 1 }}
          exit={{ opacity: 0 }}
          className="container mx-auto"
        >
          <AboutMeBio />
        </motion.div>

        {/** Counter without paddings 
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, delay: 1 }}
          exit={{ opacity: 0 }}
        >
          <AboutCounter />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, delay: 1 }}
          exit={{ opacity: 0 }}
          className="container mx-auto"
        >
          <AboutClients />
        </motion.div>
        */}
      </BasicLayout>
    </AboutMeProvider>
  );
};

export default About;
