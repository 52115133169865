import BasicLayout from "../components/layout/BasicLayout";
import ProjectsGrid from "../components/projects/ProjectsGrid";
import { ProjectsProvider } from "../context/ProjectsContext";

const Projects = () => {
  return (
    <ProjectsProvider>
      <BasicLayout>
        <div className="container mx-auto">
          <ProjectsGrid />
        </div>
      </BasicLayout>
    </ProjectsProvider>
  );
};

export default Projects;
