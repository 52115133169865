// Import images
import Edmodo from "../images/Edmodo.jpg";
import CantoralApp from "../images/CantoralApp.jpg";
import Mensis from "../images/Mensis.jpg";

export const projectsData = [
  {
    id: 1,
    title: "Cantoral App",
    category: "Web Application",
    img: CantoralApp,
    ProjectHeader: {
      title: "Project for a church",
      publishDate: "Jul 2020",
      tags: "Full-Stack",
    },
  },
  {
    id: 2,
    title: "Edmodo App",
    category: "Web Application",
    img: Edmodo,
  },
  {
    id: 3,
    title: "Mensis",
    category: "Web Application",
    img: Mensis,
  },
];
