import PropTypes from 'prop-types';

import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import AppFooter from "../shared/AppFooter";
import AppHeader from "../shared/AppHeader";
import "../../css/App.css";
import UseScrollToTop from "../../hooks/useScrollToTop";


const BasicLayout = ({children}) => {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <AnimatePresence>
      <div className=" bg-secondary-light dark:bg-primary-dark transition duration-300">
        <AppHeader />
        {children}
        <AppFooter />
        <UseScrollToTop />
      </div>
    </AnimatePresence>
  );
};

BasicLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

BasicLayout.defaultProps = {};

export default BasicLayout;
