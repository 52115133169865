import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import About from "./pages/AboutMe";
import Contact from "./pages/Contact.jsx";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import ProjectSingle from "./pages/ProjectSingle.jsx";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/projects",
    element: <Projects/>,
  },
  {
    path: "projects/single-project",
    element: <ProjectSingle/>,
  },
  {
    path: "/about",
    element: <About/>,
  },
  {
    path: "/contact",
    element: <Contact/>,
  },
]);

const App = () => {
  return (
    <RouterProvider router={router} />
  );
};

export default App;
